













import { defineComponent } from '@/plugins/composition';
import FaqTopic from '../components/FaqTopic.vue';
import Tools from './Tools.vue';

export default defineComponent({
  components: { FaqTopic, Tools },
  name: 'Menu',
});
